import React, { useState } from "react";
import clsx from "clsx";

import { getProductPrice } from "../utils";
import Button from "./Button";

export function PriceInfo({ product, quantity }) {
  return (
    <div className="price-info">
      <div className="price">₹{quantity * getProductPrice(product.price, product.discount)}</div>
      <div className="original-price">₹{quantity * product.price}</div>
      <div className="discount">{product.discount}% off</div>
      <style jsx>{`
        .price-info {
          flex: 1;
          font-family: "Lato", sans-serif;
          display: flex;
          align-items: center;
        }

        .price {
          color: #000;
          font-weight: 600;
          margin-right: 8px;
          font-size: 20px;
        }
        .original-price {
          color: #a0aec0;
          margin-right: 8px;
          font-size: 18px;
          text-decoration: line-through;
        }

        .discount {
          color: #ff8100;
          font-size: 14px;
          letter-spacing: -0.2px;
          font-weight: 600;
        }
      `}</style>
    </div>
  );
}

export function QuantitySelect({ setQuantity, quantity, product }) {
  return (
    <select
      className="form-select"
      value={quantity}
      onChange={(e) => setQuantity(Number(e.target.value))}
    >
      {Array.from({ length: product.max_quantity }).map((_, i) => (
        <option key={i} value={i + 1}>
          {i + 1} Pack{i + 1 > 1 && "s"} {product.quantity_suffix}
        </option>
      ))}
      <style jsx>{`
        .form-select {
          flex: 1;
          margin-right: 16px;
        }
      `}</style>
    </select>
  );
}

export function CheckoutPriceInfo({
  products,
  price,
  discount,
  isMobile,
  onCouponApplied,
  onCouponRemoved,
  appliedCoupon,
  initialCoupon = "",
  isSubmitting,
  noInfo = false,
}) {
  const [coupon, setCoupon] = useState(initialCoupon);
  return (
    <>
      <div
        className={clsx(
          "item price-container",
          isMobile ? "mobile-price-container" : "desktop-price-container"
        )}
      >
        <div className="header">
          <h3>PRICE DETAILS</h3>
        </div>
        <div>
          <div className="price">
            <span>Price ({products.length} item/s)</span>
            <span>₹ {price}</span>
          </div>
          {discount && (
            <div className="delivery">
              <span>
                {appliedCoupon ? `${appliedCoupon.coupon}` : `Discount`}{" "}
                <strong>
                  ({discount}
                  {appliedCoupon && appliedCoupon.hasFlatRate ? "" : "%"})
                </strong>
              </span>
              <span>
                - ₹{" "}
                {appliedCoupon && appliedCoupon.hasFlatRate
                  ? discount
                  : (price * (discount / 100)).toFixed(0)}
              </span>
            </div>
          )}
          <div className="delivery">
            <span>Delivery Fee</span>
            <span>Free</span>
          </div>
          <div className="total">
            <span>Total Payable</span>
            <span>
              ₹
              {discount
                ? appliedCoupon && appliedCoupon.hasFlatRate
                  ? price - discount
                  : getProductPrice(price, discount)
                : price}
            </span>
          </div>
        </div>
      </div>
      <p>{noInfo ? "" : "Your product is expected to be delivered in 7 working days"}</p>
      <div
        className={clsx("item", isMobile ? "mobile-coupon-container" : "desktop-coupon-container")}
      >
        <div className="header">
          <h3>APPLY COUPON</h3>
        </div>
        <div className="coupon-flex">
          <div className="coupon-container">
            <label htmlFor="coupon">
              <input
                className="form-input"
                placeholder="Enter coupon code here..."
                type="text"
                name="coupon"
                id="coupon"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                disabled={appliedCoupon || isSubmitting}
              />
            </label>
          </div>
          <div className="btns">
            {appliedCoupon ? (
              <Button width={100} isSolid={false} onClick={onCouponRemoved}>
                Remove
              </Button>
            ) : (
              <Button width={100} onClick={() => onCouponApplied(coupon)}>
                Apply
              </Button>
            )}
          </div>
        </div>
      </div>
      <style jsx>{`
        p {
          margin: 0;
          margin-top: 8px;
          font-style: italic;
          font-weight: bold;
          margin-bottom: 32px;
          font-family: "Lato", sans-serif;
          text-align: center;
          color: #888;
        }

        .item {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
        }

        .item .header {
          padding: 8px 16px;
          background: #f5f0eb;
        }

        .item .header h3 {
          margin: 0;
          text-transform: uppercase;
          font-weight: 700;
          font-family: "Lato", sans-serif;
        }
        .desktop-price-container {
          width: 100%;
        }

        .mobile-price-container {
          display: none;
        }

        .price-container .price,
        .price-container .delivery,
        .price-container .total {
          display: flex;
          justify-content: space-between;
          padding: 16px;
        }

        .price-container span {
          font-size: 1.125rem;
          font-family: "Open Sans", sans-serif;
        }

        .price-container .price span:last-child,
        .price-container .delivery span:last-child,
        .price-container .total span:last-child {
          font-family: "Lato", sans-serif;
          font-size: 1.25rem;
          font-weight: 700;
        }

        .price-container .delivery span:last-child {
          color: #48bb78;
        }

        .price-container .total {
          border-top: 1px dashed #e2e8f0;
        }

        .price-container .total span:first-child {
          font-weight: 700;
        }

        .coupon-flex {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 10px;
        }

        .coupon-container {
          flex: 1;
          padding: 0;
        }

        .btns {
          display: flex;
          justify-content: center;
          padding: 0;
          margin-left: 8px;
        }

        .mobile-coupon-container {
          display: none;
        }

        label {
          display: block;
          font-family: "Open Sans", sans-serif;
        }

        label:not(:first-child) {
          margin-top: 16px;
        }

        label span {
          color: #4a5568;
        }

        .form-input {
          font-family: "Lato", sans-serif;
          width: 100%;
          margin-top: 0.25rem;
          display: block;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #fff;
          border: 1px solid #e2e8f0;
          border-radius: 0.25rem;
          padding: 0.5rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          outline: none;
        }
        @media (max-width: 940px) {
          .mobile-price-container,
          .mobile-coupon-container {
            display: block;
          }
        }
      `}</style>
    </>
  );
}
