import React, { useState } from "react";
import { graphql, useStaticQuery, navigate } from "gatsby";

import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { HTMLContent } from "./Content";
import Breadcrumbs from "./Breadcrumbs";
import Button from "./Button";
import { QuantitySelect, PriceInfo, CheckoutPriceInfo } from "./Shop";
import { getProductPrice } from "../utils/index";
import { openRzp, getOrderId } from "../utils/rzp";
import { useMisc } from "../hooks/useMisc";
import { useEffect } from "react";

const initialFormState = {
  name: "",
  email: "",
  phone: "",
  pincode: "",
  locality: "",
  address: "",
  city: "",
  state: "",
  landmark: "",
  paymentMethod: "1",
  coupon: "",
};

export default function Checkout({ product, otherProducts }) {
  const { coupons } = useMisc();
  const { purchaseSuccessImage } = useStaticQuery(graphql`
    query {
      purchaseSuccessImage: file(relativePath: { eq: "purchare-success.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  const [products, setProducts] = useState(() => {
    product.quantity =
      typeof window !== "undefined"
        ? Number(window.localStorage.getItem(product.slug)) || 1
        : 1;
    return [product];
  });
  const [remainingProducts, setRemainingProducts] = useState(
    () => otherProducts
  );

  const [form, setFormValue] = useState(initialFormState);
  const [orderId, setOrderId] = useState();
  const [appliedCoupon, setAppliedCoupon] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [price, setPrice] = useState(
    () => (product.quantity || 1) * product.price
  );
  const [discount, setDiscount] = useState(() => product.discount);

  useEffect(() => {
    if (appliedCoupon) {
      setDiscount(appliedCoupon.discount);
    } else {
      setDiscount(product.discount);
    }
  }, [JSON.stringify(appliedCoupon)]);

  const activeCoupons = coupons.filter(({ item }) => item.isActive);
  function onCouponApplied(couponCode) {
    if (!couponCode) {
      return alert(`Please enter coupon code`);
    }
    const coupon = activeCoupons.find(
      ({ item }) => item.coupon.toLowerCase() === couponCode.toLowerCase()
    );
    if (!coupon) {
      return alert(`This coupon code is invalid or expired`);
    }
    setAppliedCoupon(coupon.item);
  }

  function onCouponRemoved() {
    setAppliedCoupon(undefined);
  }

  function addProduct(product) {
    setProducts((products) => {
      const newProducts = [...products, product];
      const price = newProducts.reduce((p, n) => {
        return p + (n.quantity || 1) * n.price;
      }, 0);
      setPrice(price);
      return newProducts;
    });
    setRemainingProducts((products) =>
      products.filter(({ slug }) => slug !== product.slug)
    );
  }

  function removeProduct(product) {
    setRemainingProducts((products) => [...products, product]);
    setProducts((products) => {
      const newProducts = products.filter(({ slug }) => slug !== product.slug);
      const price = newProducts.reduce((p, n) => {
        return p + (n.quantity || 1) * n.price;
      }, 0);
      setPrice(price);
      return newProducts;
    });
  }
  function onQuantityChange(value, product, index) {
    setProducts((products) => {
      const newProducts = [
        ...products.slice(0, index),
        { ...product, quantity: value },
        ...products.slice(index + 1),
      ];
      const price = newProducts.reduce((p, n) => {
        return p + (n.quantity || 1) * n.price;
      }, 0);
      setPrice(price);
      window.localStorage.setItem(product.slug, JSON.stringify(Number(value)));
      return newProducts;
    });
  }
  function onOtherQuantityChange(value, product, index) {
    setRemainingProducts((products) => {
      const newProducts = [
        ...products.slice(0, index),
        { ...product, quantity: value },
        ...products.slice(index + 1),
      ];
      const price = newProducts.reduce((p, n) => {
        return p + (n.quantity || 1) * n.price;
      }, 0);
      setPrice(price);
      window.localStorage.setItem(product.slug, JSON.stringify(Number(value)));
      return newProducts;
    });
  }
  async function onFormChange(e) {
    const { name, value } = e.target;
    setFormValue((values) => ({ ...values, [name]: value }));
  }
  async function onSubmit(e) {
    const error = validateForm(form);
    if (error) {
      return alert(error);
    }
    try {
      setIsSubmitting(true);
      const orderData = {
        ...form,
        coupon: appliedCoupon ? appliedCoupon.coupon : null,
        discount: discount
          ? appliedCoupon && appliedCoupon.hasFlatRate
            ? discount
            : (price * (discount / 100)).toFixed(0)
          : 0,
        amount: discount
          ? appliedCoupon && appliedCoupon.hasFlatRate
            ? price - discount
            : getProductPrice(price, discount)
          : price,
        products: products.map((product) => ({
          name: product.name,
          quantity: product.quantity || 1,
          price: (product.quantity || 1) * product.price,
          image: `https://mycoforest.com${product.images[0].childImageSharp.original.src}`,
        })),
        address: `${form.address}\n${form.city}\n${form.state}-${form.pincode}\nLocality: ${form.locality}\nLandmark: ${form.landmark}`,
        url: window.location.href,
      };
      if (form.paymentMethod === "1") {
        const { id } = await getOrderId(orderData);
        setIsSubmitting(false);
        setOrderId(id);
        window.scrollTo(0, 0);
        setFormValue(initialFormState);
        navigate(`/shop/thankyou?orderId=${id}`);
      } else {
        const { id } = await openRzp(orderData);
        setIsSubmitting(false);
        setOrderId(id);
        window.scrollTo(0, 0);
        setFormValue(initialFormState);
        navigate(`/shop/thankyou?orderId=${id}`);
      }
    } catch (e) {
      console.log(e);
      setIsSubmitting(false);
      alert(e.message || "Failed try again!");
    }
  }

  return (
    <section>
      <Breadcrumbs
        items={[
          { title: "MycoForest", href: "/", active: true },
          { title: "Shop", href: "/shop", active: true },
          {
            title: product.name,
            href: product.slug,
            shortTitle: "Product",
            active: true,
          },
          { title: "Buy", href: `${product.slug}/buy`, active: false },
        ]}
      />
      <div className="container">
        <div className="content">
          <div className="details">
            {products.length > 0 && (
              <div className="item">
                <div className="header">
                  <h3>Order Summary</h3>
                </div>
                {products.map((product, index) => (
                  <div className="product" key={index}>
                    <div className="image-container">
                      <PreviewCompatibleImage
                        imageInfo={product.images[0]}
                        alt="product image"
                      />
                      <QuantitySelect
                        quantity={product.quantity}
                        setQuantity={(q) => onQuantityChange(q, product, index)}
                        product={product}
                      />
                    </div>
                    <div className="info">
                      <h4>{product.name}</h4>
                      <div className="info-container">
                        <PriceInfo
                          product={product}
                          quantity={product.quantity || 1}
                        />
                        <HTMLContent
                          className="markdown"
                          content={product.description}
                        />
                        <button onClick={() => removeProduct(product)}>
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {remainingProducts.length > 0 && (
              <div className="item other">
                <div className="header">
                  <h3>Recommended Products</h3>
                </div>
                <div className="other-items">
                  {remainingProducts.map((product, index) => (
                    <div className="product" key={index}>
                      <div className="image-container">
                        <PreviewCompatibleImage
                          imageInfo={product.images[0]}
                          alt="product image"
                        />
                        <QuantitySelect
                          quantity={product.quantity}
                          setQuantity={(q) =>
                            onOtherQuantityChange(q, product, index)
                          }
                          product={product}
                        />
                      </div>
                      <div className="info">
                        <h4>{product.name}</h4>
                        <div className="info-container">
                          <PriceInfo
                            product={product}
                            quantity={product.quantity || 1}
                          />
                          <HTMLContent
                            className="markdown"
                            content={product.description}
                          />
                          <button
                            className="add"
                            onClick={() => addProduct(product)}
                          >
                            Add TO Cart
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="item">
              <div className="header">
                <h3>Contact Details</h3>
              </div>
              <div className="inputs">
                <p>
                  You will get a confirmation email & message on the given
                  contact details:
                </p>
                <label htmlFor="name">
                  <span>
                    Name <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your name here..."
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={form.name}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
                <label htmlFor="email">
                  <span>
                    Email <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your email here..."
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={form.email}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
                <label htmlFor="phone">
                  <span>
                    Mobile Number <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your mobile number here..."
                    type="phone"
                    name="phone"
                    id="phone"
                    required
                    value={form.phone}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
              </div>
            </div>
            <div className="item">
              <div className="header">
                <h3>DELIVERY ADDRESS</h3>
              </div>
              <div className="inputs">
                <label htmlFor="pincode">
                  <span>
                    Pincode <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your pincode here..."
                    type="text"
                    inputMode="numeric"
                    name="pincode"
                    id="pincode"
                    required
                    value={form.pincode}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
                <label htmlFor="address">
                  <span>
                    Address <sup>*</sup>
                  </span>
                  <textarea
                    className="textarea"
                    placeholder="Your address here..."
                    type="text"
                    name="address"
                    id="address"
                    cols="30"
                    rows="4"
                    required
                    value={form.address}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
                <label htmlFor="city">
                  <span>
                    City/District/Town <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your city here..."
                    type="text"
                    name="city"
                    id="city"
                    required
                    value={form.city}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
                <label htmlFor="state">
                  <span>
                    State <sup>*</sup>
                  </span>
                  <input
                    className="form-input"
                    placeholder="Your state here..."
                    type="text"
                    name="state"
                    id="state"
                    required
                    value={form.state}
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                </label>
              </div>
            </div>
            <CheckoutPriceInfo
              products={products}
              price={price}
              discount={discount}
              isMobile
              onCouponApplied={onCouponApplied}
              appliedCoupon={appliedCoupon}
              isSubmitting={isSubmitting}
              onCouponRemoved={onCouponRemoved}
            />
            <div className="item">
              <div className="header">
                <h3>Payment Details</h3>
              </div>
              <div className="inputs radio">
                <label htmlFor="cod">
                  <input
                    type="radio"
                    className="form-radio"
                    name="paymentMethod"
                    id="cod"
                    checked={form.paymentMethod === "1"}
                    value="1"
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                  <span>Cash on Delivery</span>
                </label>
                <label htmlFor="rzp">
                  <input
                    type="radio"
                    className="form-radio"
                    name="paymentMethod"
                    id="rzp"
                    checked={form.paymentMethod === "2"}
                    value="2"
                    onChange={onFormChange}
                    disabled={isSubmitting}
                  />
                  <span>Pay Now Using (credit/debit cards or UPI)</span>
                </label>
                <div className="submit-container">
                  <Button
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                    onClick={onSubmit}
                  >
                    Place Order
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="sidebar">
            <div className="sidebar-container">
              <CheckoutPriceInfo
                products={products}
                price={price}
                discount={discount}
                onCouponApplied={onCouponApplied}
                appliedCoupon={appliedCoupon}
                isSubmitting={isSubmitting}
                onCouponRemoved={onCouponRemoved}
              />
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        section {
          padding: 32px;
        }

        .container {
          max-width: 1278px;
          margin: 0 auto;
        }

        .content {
          display: flex;
        }

        .details {
          margin-right: 32px;
          flex: 2;
        }

        .item {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
            0 1px 2px 0 rgba(0, 0, 0, 0.06);
          margin-bottom: 32px;
        }

        .item .header {
          padding: 8px 16px;
          background: #f5f0eb;
        }

        .item .header h3 {
          margin: 0;
          text-transform: uppercase;
          font-weight: 700;
          font-family: "Lato", sans-serif;
        }

        .item .product {
          padding: 20px 16px;
          display: flex;
        }

        .product .image-container {
          width: 250px;
          margin-right: 16px;
        }

        .product .image-container :global(.form-select) {
          width: 250px;
          margin-right: 0;
        }

        .product .image-container :global(.gatsby-image-wrapper) {
          margin-bottom: 8px;
          height: 190px;
          width: 250px;
        }

        .product .image-container :global(.gatsby-image-wrapper) :global(img) {
          object-fit: contain !important;
        }

        .product h4 {
          margin: 0;
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          font-size: 24px;
          margin-bottom: 8px;
        }

        .product :global(.markdown) :global(p) {
          margin-top: 8px;
          margin-bottom: 0;
          overflow: hidden;
          position: relative;
          line-height: 1.4em;
          max-height: 5.6em;
          text-align: justify;
          margin-right: 20px;
          padding-right: 20px;
          font-size: 15px;
        }

        .product button {
          text-transform: uppercase;
          color: #e53e3e;
          padding: 12px;
          border-radius: 8px;
          font-weight: 700;
          outline: none;
          border: none;
          background: transparent;
          font-size: 12px;
          margin-top: 10px;
          -webkit-appearance: button;
          font-family: "Lato", sans-serif;
          cursor: pointer;
          transition: all 0.2s;
          background: #fff5f5;
        }

        .product button.add {
          color: #ffffff;
          background: #48bb78;
        }

        .product button:hover {
          transform: scale(1.01);
        }

        .product :global(.markdown) :global(p):after {
          content: "";
          position: absolute;
          right: 0;
          width: 1em;
          height: 1em;
          margin-top: 0.2em;
          background: white;
        }

        .product :global(.markdown) :global(p):before {
          content: ". . .";
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .inputs {
          padding: 32px;
        }

        .inputs p {
          margin: 0;
          padding: 0;
          font-family: "Open Sans", sans-serif;
        }

        .radio span {
          margin-left: 4px;
        }

        label {
          display: block;
          font-family: "Open Sans", sans-serif;
        }

        label:not(:first-child) {
          margin-top: 16px;
        }

        label span {
          color: #4a5568;
        }

        label sup {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
          top: -0.5em;
          color: #f56565;
        }

        .inputs .form-input,
        .inputs .textarea {
          font-family: "Lato", sans-serif;
          width: 100%;
          margin-top: 0.25rem;
          display: block;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background-color: #fff;
          border: 1px solid #e2e8f0;
          border-radius: 0.25rem;
          padding: 0.5rem 0.75rem;
          font-size: 1rem;
          line-height: 1.5;
          outline: none;
        }

        .submit-container {
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;
        }

        .sidebar {
          flex: 1;
        }

        .sidebar-container {
          position: sticky;
          top: 100px;
          bottom: 100px;
        }

        @media (max-width: 1140px) {
          .product h4 {
            font-size: 20px;
          }
        }

        @media (max-width: 1040px) {
          .product :global(.markdown) :global(p) {
            max-height: 4.2em;
          }
        }

        @media (max-width: 940px) {
          .content {
            flex-direction: column;
          }

          .sidebar {
            display: none;
          }

          .submit-container {
            margin-top: 32px;
            display: flex;
            justify-content: center;
          }

          .details {
            margin-right: 0;
          }

          .product h4 {
            font-size: 24px;
          }

          .product :global(.markdown) :global(p) {
            max-height: 5.6em;
          }
        }

        @media (max-width: 790px) {
          .product h4 {
            font-size: 20px;
          }

          .product :global(.markdown) :global(p) {
            max-height: 5.6em;
          }
        }

        @media (max-width: 620px) {
          .item .product {
            flex-direction: column;
          }

          .product :global(.markdown) {
            display: none;
          }

          .product .info-container {
            display: flex;
            align-items: baseline;
          }

          .product .image-container {
            margin: 0;
            margin-bottom: 16px;
            width: 100%;
          }

          .inputs {
            padding: 32px 20px;
          }

          .product .image-container :global(.gatsby-image-wrapper) {
            width: 100%;
            height: auto;
          }

          .product .image-container :global(.form-select) {
            width: 100%;
          }

          .other .other-items {
            display: flex;
          }

          .other .other-items > * {
            flex: 1;
          }

          .other .other-items .info-container {
            flex-direction: column;
          }

          .other .other-items .info-container .add {
            width: 100%;
            padding: 10px 12px;
          }

          .other .other-items h4 {
            font-size: 13px;
          }

          .other .other-items :global(.form-select) {
            padding: 4px 2.5rem 4px 4px;
            font-size: 12px;
          }

          .other .other-items :global(.price) {
            font-size: 14px;
          }
          .other .other-items :global(.original-price) {
            font-size: 12px;
          }
          .other .other-items :global(.discount) {
            font-size: 10px;
          }
        }

        @media (max-width: 480px) {
          section {
            padding: 32px 20px;
          }
        }
      `}</style>
    </section>
  );
}

function validateForm(form) {
  if (!form.name) {
    return `Please enter your name`;
  }
  if (!form.email) {
    return `Please enter your email`;
  }
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      form.email
    )
  ) {
    return `Please enter a valid email`;
  }
  if (!form.phone) {
    return `Please enter your Mobile number`;
  }
  if (!/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(form.phone)) {
    return `Please enter a valid Mobile number`;
  }
  if (!form.pincode) {
    return `Please enter your pincode`;
  }
  if (!/^[1-9][0-9]{5}$/.test(form.pincode)) {
    return `Please enter a valid pincode`;
  }
  if (!form.address) {
    return `Please enter your address`;
  }
  if (!form.city) {
    return `Please enter your City/District/Town`;
  }
  if (!form.state) {
    return `Please enter your state`;
  }
  return false;
}
