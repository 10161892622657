const RZP_KEY =
  process.env.NODE_ENV === "development"
    ? `rzp_test_JVR9YUSJrpPFSX`
    : `rzp_live_GPid0gGqSxDa5j`;

const DEFAULT_TITLE = `MycoForest`;
const DEFAULT_DESCRIPTION = `MycoForest Store...`;
const LOGO = `https://mycoforest.com/static/484cecd8153a79effddab89c7023ec31/b6424/logo_horizontal.webp`;
const THEME_COLOR = `#ff5858`;

const STORE_BASE_URL =
  process.env.NODE_ENV === "development"
    ? `http://localhost:3000`
    : `https://store.mycoforest.com`;

const STORE_ENDPOINTS = {
  create: `${STORE_BASE_URL}/api/orders/create`,
  update: `${STORE_BASE_URL}/api/orders/update`
};

export async function getOrderId(data) {
  return fetch(STORE_ENDPOINTS.create, {
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  }).then(res => {
    if (res.ok) {
      return res.json();
    }
  });
}
async function updateStatus(data) {
  return fetch(STORE_ENDPOINTS.update, {
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  });
}
export async function openRzp(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const { email, phone, amount } = data;
      const order = await getOrderId(data);
      const options = {
        key: RZP_KEY,
        amount: amount * 100, // in paisa
        order_id: order.id,
        name: DEFAULT_TITLE,
        description: DEFAULT_DESCRIPTION,
        image: LOGO,
        handler: async res => {
          try {
            await updateStatus(
              Object.assign(Object.assign({}, res), {
                status: "captured"
              })
            );
            resolve({ id: order.id });
          } catch (error) {
            reject(error);
          }
        },
        modal: {
          ondismiss: async () => {
            await updateStatus({
              razorpay_order_id: order.id,
              status: "failed"
            });
            reject(
              new Error(
                `Payment widget is closed without completing payment. Please try again!`
              )
            );
          }
        },
        prefill: {
          email,
          contact: phone
        },
        theme: {
          color: THEME_COLOR
        },
        method: {
          netbanking: true,
          card: true,
          wallet: true,
          upi: true
        }
      };
      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      reject(error);
    }
  });
}
