import { graphql, useStaticQuery } from "gatsby";

export const useProducts = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        limit: 1000
        filter: { frontmatter: { templateKey: { eq: "product" } } }
      ) {
        edges {
          node {
            frontmatter {
              seo {
                title
                description
                image {
                  publicURL
                }
              }

              name
              max_quantity
              price
              discount
              inStock
              position
              quantity_suffix
              rating
              reviews
              sku
              mpn
              reviewsList {
                author
                datePublished
                reviewBody
                name
                reviewRating {
                  ratingValue
                }
              }
              specifications {
                brand
              }
              images {
                childImageSharp {
                  original {
                    src
                  }
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            fields {
              slug
              frontmattermd {
                highlights {
                  html
                }
                description {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.edges
    .map(({ node }) => {
      return {
        ...node.frontmatter,
        slug: node.fields.slug,
        highlights: node.fields.frontmattermd.highlights.html,
        description: node.fields.frontmattermd.description.html
      };
    })
    .sort((a, b) => a.position - b.position);
};
