import React from "react";
import { Link } from "gatsby";
import { IoIosArrowForward } from "react-icons/io";
import clsx from "clsx";
import { BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import useSiteMetadata from "./SiteMetadata";

export default ({ items, inverted = false, inline = false }) => {
  const { siteUrl } = useSiteMetadata();
  return (
    <>
      <BreadcrumbJsonLd
        itemListElements={items.map((item, index) => ({
          position: index + 1,
          name: item.title,
          item: `${siteUrl}${item.href}`
        }))}
      />
      <ul
        className={clsx(
          "breadcrumbs",
          inverted && "inverted",
          inline && "inline"
        )}
      >
        {items.map((item, index) => (
          <li key={index}>
            {item.active ? (
              <>
                <Link to={item.href}>
                  <span className="title">{item.title}</span>
                  <span className="short-title">
                    {item.shortTitle || item.title}
                  </span>
                </Link>
                <span className="icon">
                  <IoIosArrowForward />
                </span>
              </>
            ) : (
              <>
                <span className="title">{item.title}</span>
                <span className="short-title">
                  {item.shortTitle || item.title}
                </span>
              </>
            )}
          </li>
        ))}
        <style jsx>{`
          .breadcrumbs {
            padding: 0;
            margin: 0;
            list-style: none;
            display: flex;
            max-width: 1278px;
            margin: 0 auto;
            margin-bottom: 24px;
            font-family: "Lato", sans-serif;
            font-weight: 400;
          }

          .breadcrumbs.inline {
            max-width: unset;
            margin: 0;
          }

          .breadcrumbs li {
            display: flex;
            align-items: center;
          }

          .breadcrumbs li .icon {
            margin-left: 4px;
          }

          .breadcrumbs :global(a),
          .breadcrumbs span {
            display: flex;
            align-items: center;
            color: #a9a9a9;
            text-decoration: none;
          }

          .breadcrumbs :global(a):hover {
            color: #6b6b6b;
            text-decoration: underline;
          }

          .breadcrumbs.inverted :global(a),
          .breadcrumbs.inverted span {
            color: #eee;
          }

          .breadcrumbs.inverted :global(a):hover {
            color: #fff;
          }

          .breadcrumbs li > span {
            font-weight: 600;
          }

          .breadcrumbs li:not(:last-child),
          .breadcrumbs li:not(:last-child) {
            margin-right: 4px;
          }

          .breadcrumbs span.short-title {
            display: none;
          }

          @media (max-width: 900px) {
            .breadcrumbs span.title {
              display: none;
            }
            .breadcrumbs span.short-title {
              display: block;
            }
          }
        `}</style>
      </ul>
    </>
  );
};
