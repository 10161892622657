import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Checkout from "../components/Checkout";
import { useProducts } from "../hooks/useProducts";
import useSiteMetadata from "../components/SiteMetadata";

const CheckoutPage = ({ pageContext }) => {
  // const [cartItems, setCartItems] = useLocalStorage("cart", []);
  const products = useProducts();
  const { siteUrl } = useSiteMetadata();
  const product = products.find(product => product.slug === pageContext.slug);
  const otherProducts = products
    .filter(product => product.slug !== pageContext.slug)
    .filter(product => product.inStock);
  return (
    <Layout whiteHeader>
      <GatsbySeo
        title={product.seo.title}
        description={product.seo.description}
        openGraph={{
          url: `${siteUrl}${product.slug}/buy`,
          title: product.seo.title,
          description: product.seo.description,
          images: [{ url: `${siteUrl}${product.seo.image.publicURL}` }],
          site_name: "MycoForest"
        }}
      />
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" async />
      </Helmet>
      <Checkout product={product} otherProducts={otherProducts} />
    </Layout>
  );
};

export default CheckoutPage;
